const awsmobile = {
    "aws_project_region": "us-east-1",
    API: {
        endpoints: [
            {
                name: "TranslateService",
                endpoint: "https://12s8z8unfe.execute-api.us-east-1.amazonaws.com/prod"
            }
        ]
    }
};

export default awsmobile;